* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
  min-height: 100vh;
}

.content {
  overflow: hidden;
  z-index: -10;
}

h1 {
  font-size: 60px;
  color: #fff;
}