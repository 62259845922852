@import url(https://fonts.googleapis.com/css2?family=Rajdhani:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Epilogue:wght@300&display=swap);
:root {
--font-family2: Epilogue, sans-serif;
font-family: 'Rajdhani', sans-serif;

--hp: #78B392;
--pow: #F54F4F;
--en: #FF9200;
--arm: #728794;
--sth: #000;
--mad: #BF2651;
--cr: #4D6A94;

--common-rarity: #78B392;

--myrawb0ts-bg: linear-gradient(180deg, #357985 0%, #405578 100%);
--mint-bg: radial-gradient(circle at 50% 30%, #728794 0%, #3D3957 60%);

--myrawb0ts-info-bg: #303D50;
--mint-info-bg: #524a63;
--myrawb0ts-modal-bg: #303D50;
--myrawb0ts-modal-overlay-bg: #1f2835e8;
--myrawb0ts-armory-bots-bg: #1a1c2b50;

--myrawb0ts-toptitle: #BF2651;
--mint-toptitle: #ff9200;

--coming-soon: #555;
--mint-unavailable: rgb(255, 0, 0);
--mint-available: rgb(9, 255, 0);
--tooltip-bg: #222;
--unavailable-color: #333;
--warverse-tooltip: #4c8f82;
--universe-grid: #1A1C2B;
--menu-hover: #BF2651;

--opensea: #99c2db;

--p-default: #728794;
--color-bg: #303D50;
--navbar-bg: #091b3c;
--menu-bg: #000;
--button-bg-hover: #78b392;
--button-bg: #4c8f82;
--button-utility-bg: #405578;
--gradient-bg: linear-gradient(180deg, #357985 0%, #405578 100%);
--gradient-bg-dark: linear-gradient(160deg, #00051D 0%, #091b3c 50%); /*linear-gradient(177deg, #303D50 0%, rgba(26, 28, 43, 1) 80%);*/
--gradient-bg-armory: linear-gradient(177deg, #303D50 0%, #1a1c2b 80%);
}

a {
  color: unset;
  text-decoration: none;
}

h2 {
  font-size: 64px;
  color: #fff;
  line-height: 58px;
  padding-bottom: 20px;
}

.default__bg {
  background: #000;
}

.color-change-2x {
  background: #4c8f82;
  background: var(--button-bg);
}

.color-change-2x:hover {
	-webkit-animation: color-change-2x 1s linear infinite alternate both;
	        animation: color-change-2x 1s linear infinite alternate both;
}

 @-webkit-keyframes color-change-2x {
  0% {
    background: #4c8f82;
  }
  50% {
    background: #ff9200;
  }
  100% {
    background: #BF2651;
  }
}
@keyframes color-change-2x {
  0% {
    background: #4c8f82;
  }
  50% {
    background: #ff9200;
  }
  100% {
    background: #BF2651;
  }
}


.gradient__bg
{
    /* ff 3.6+ */ 

    /* safari 5.1+,chrome 10+ */

    /* opera 11.10+ */
    background:-o-linear-gradient(180deg, rgba(53, 121, 133, 1) 0%, rgba(64, 85, 120, 1) 100%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#405578', endColorstr='#357985', GradientType=1 );

    /* ie 10+ */

    /* global 94%+ browsers support */
    background:linear-gradient(180deg, rgba(53, 121, 133, 1) 0%, rgba(64, 85, 120, 1) 100%);
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.slide-bottom {
	-webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

 @-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-300px);
            transform: translateY(-300px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-300px);
            transform: translateY(-300px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

.slide-in-blurred-top {
	-webkit-animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	        animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

 @-webkit-keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
            transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
            transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

.slit-out-vertical {
	-webkit-animation: slit-out-vertical 0.5s ease-in both;
	        animation: slit-out-vertical 0.5s ease-in both;
}

 @-webkit-keyframes slit-out-vertical {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
    opacity: 1;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
            transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
            transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
}
@keyframes slit-out-vertical {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
    opacity: 1;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
            transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
            transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
}

.slide-out-blurred-top {
	-webkit-animation: slide-out-blurred-top 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
	        animation: slide-out-blurred-top 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
}

 @-webkit-keyframes slide-out-blurred-top {
  0% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
            transform: translateY(-1000px) scaleY(2) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}
@keyframes slide-out-blurred-top {
  0% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
            transform: translateY(-1000px) scaleY(2) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}

.fade-in {
	-webkit-animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.flip-vertical-left {
	-webkit-animation: flip-vertical-left 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: flip-vertical-left 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

@-webkit-keyframes flip-vertical-left {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
  }
}
@keyframes flip-vertical-left {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
  }
}



@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }
  
  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }
  
  .section__margin {
    margin: 4rem 2rem;
  }
}

.unavailable p {
  color: #333 !important;
  color: var(--unavailable-color) !important;
}

.unavailable p:hover {
  border: 0px !important;
}

/* START TOOLTIP STYLES */
[tooltip] {
  position: relative; /* opinion 1 */
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  font-family: 'Rajdhani', sans-serif !important;
  text-transform: uppercase; /* opinion 2 */
  font-size: .9em; /* opinion 3 */
  line-height: 1;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  position: absolute;
  margin-bottom: -5px;
  display: none;
  opacity: 0;
  text-rendering: optimizeLegibility;
}
[tooltip]::before {
  content: '';
  border: 0px solid #000; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */

}
[tooltip]::after {
  content: attr(tooltip); /* magic! */
  
  /* most of the rest of this is opinion */
  font-family: Helvetica, sans-serif;
  text-align: center;
  
  /* 
    Let the content set the size of the tooltips 
    but this will also keep them from being obnoxious
    */
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: .3ch;
  box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.259);
  background: #222;
  background: var(--tooltip-bg);
  color: #fff;
  z-index: 1000; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;

}

/* don't show empty tooltips */
[tooltip='']::before,
[tooltip='']::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: rgb(0, 0, 0);
}
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  -webkit-transform: translate(-50%, -.5em);
          transform: translate(-50%, -.5em);
}

/* FLOW: DOWN */
[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: rgb(0, 0, 0);
}
[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}
[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  -webkit-transform: translate(-50%, .5em);
          transform: translate(-50%, .5em);
}

/* FLOW: LEFT */
[tooltip][flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: rgb(0, 0, 0);
  left: calc(0em - 5px);
  -webkit-transform: translate(-.5em, -50%);
          transform: translate(-.5em, -50%);
}
[tooltip][flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  -webkit-transform: translate(-.5em, -50%);
          transform: translate(-.5em, -50%);
}

/* FLOW: RIGHT */
[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: rgb(0, 0, 0);
  right: calc(0em - 5px);
  -webkit-transform: translate(.5em, -50%);
          transform: translate(.5em, -50%);
}
[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  -webkit-transform: translate(.5em, -50%);
          transform: translate(.5em, -50%);
}

/* KEYFRAMES */
@-webkit-keyframes tooltips-vert {
  to {
    opacity: .9;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
}
@keyframes tooltips-vert {
  to {
    opacity: .9;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
}

@-webkit-keyframes tooltips-horz {
  to {
    opacity: .9;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: .9;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
  }
}

/* FX All The Things */ 
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  -webkit-animation: tooltips-vert 300ms ease-out forwards;
          animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  -webkit-animation: tooltips-horz 300ms ease-out forwards;
          animation: tooltips-horz 300ms ease-out forwards;
}
.rawb0tsApp__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--gradient-bg-dark);
  padding: 1rem 6rem;
  z-index: 999;
}

.rawb0tsApp__navbar-links {
flex: 1 1;
display: flex;
justify-content: flex-start;
align-items: center;
}

.rawb0tsApp__navbar-links_logo {
  margin-right: 2rem;
  margin-top: 10px;
}

.rawb0tsApp__navbar-links_logo img {
  width: 126px;
  height: 40px;
}

.rawb0tsApp__navbar-links_container {
  display: flex;
  flex-direction: row;
}

.rawb0tsApp__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.rawb0tsApp__navbar-links_container p,
.rawb0tsApp__navbar-menu_container p {
  color: #fff;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  margin: 0 1rem;
  cursor: pointer;
}

.rawb0tsApp__navbar-links_container p:hover {
  color: #BF2651;
  border-bottom:5px solid #bf2651;
  padding-bottom: 5px;
  margin-bottom: -10px;
}

.rawb0tsApp__navbar-sign p {
  color: #fff;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  text-transform: uppercase;
  margin: 0 1rem;
  cursor: pointer;
}

.rawb0tsApp__navbar-sign button,
.rawb0tsApp__navbar-menu_container-links_sign button {
  padding: 1rem 2rem;
  margin-left: 0rem;
  color: #fff;
  font-family: var(--font-family);
  font-size: 18px;
  text-transform: uppercase;
  background: var(--button-bg);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  min-width: 230px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}

.rawb0tsApp__navbar-sign button:hover,
.rawb0tsApp__navbar-menu_container-links_sign button:hover {
  background: var(--button-bg-hover);
}

.rawb0tsApp__navbar-sign button img,
.rawb0tsApp__navbar-menu_container-links_sign button img {
  width: 32px;
  height: 32px;
  margin-right: 1rem;
}

.rawb0tsApp__navbar-sign button p,
.rawb0tsApp__navbar-menu_container-links_sign button p {
  padding: 0rem 0rem;
  font-size: 18px;
}

.rawb0tsApp__navbar-menu {
  margin-left: 0rem;
  display: none;
  position: relative;
}

.rawb0tsApp__navbar-menu svg {
  cursor: pointer;
  margin-left: 1rem;
}

.rawb0tsApp__navbar-menu_container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  text-align: start;
  background: var(--navbar-bg);
  padding: 1.5rem 3rem;
  position: absolute;
  top: 3rem;
  right: -10px;
  min-width: 350px;
  border-radius: 5px;
  z-index: 01;
}

.rawb0tsApp__navbar-menu_container p {
  margin: 0.7rem 0;
}

.rawb0tsApp__navbar-menu_container a:hover {
  color: #BF2651;
  border-right:0.35rem solid #bf2651;
  padding-right: 0.5rem;
  margin-right: -0.85rem;
}

.rawb0tsApp__navbar-menu_container-links {
  max-width: -webkit-max-content;
  max-width: max-content;
}

.rawb0tsApp__navbar-menu_container-links_sign {
  display: none;
}

@media screen and (max-width: 1050px) {

  .rawb0tsApp__navbar-menu_container-links p:hover {
    color: #BF2651;
    border-right:5px solid #bf2651;
    padding-right: 5px;
    margin-right: -10px;
  }

  .rawb0tsApp__navbar-links_container {
    display: none;
  }

  .rawb0tsApp__navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .rawb0tsApp__navbar-sign,
  .rawb0tsApp__navbar-links_container
   {
    display: none;
  }

  .rawb0tsApp__navbar-menu,
  .rawb0tsApp__navbar-menu_container-links_sign {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  }

  .rawb0tsApp__navbar-menu_container-links_sign p:hover {
    color: #fff;
    border: 0px;
    padding-right: 10px;
  }

  .rawb0tsApp__navbar {
    padding: 1rem 2rem;
  }
}

@media screen and (max-width: 500px) {
  .rawb0tsApp__navbar {
    padding: 1rem 2rem;
  }

  .rawb0tsApp__navbar-sign {
    display: none;
  }

  .rawb0tsApp__navbar-menu_container-links_sign {
    margin-top: 1rem;
    display: block;
  }

}
/*.rawb0tsApp__mint {
  background: var(--mint-bg);
  overflow: hidden;
  min-height: 100vh;
  margin-top: -6rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.rawb0tsApp__mint {
  padding: 11rem 3rem 0rem 3rem;
  background-image: url('../../assets/images/mint_BG01.png');
  background-blend-mode: overlay;
  overflow: hidden;
  min-height: 100vh;
  margin-top: -6rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items:center;
}*/

.rawb0tsApp__mint {
  overflow: hidden;
  background: var(--mint-bg);
  min-height: 100vh;
  margin-top: -6rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.rawb0tsApp__mint-bg {
  min-height: 100vh;
  width: 100%;
  padding: 11rem 2rem 2rem 2rem;
  background-blend-mode: overlay;
  background-image: url(../../static/media/mint_BG01.60bec15c.png);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.rawb0tsApp__mint h3 {
  color: var(--mint-toptitle);
}

.rawb0tsApp__mint-box {
  display: flex;
  flex-direction: column;
  background: var(--mint-info-bg);
  padding: 3rem;
  border-bottom: solid #000 15px;
  max-width: 980px;
} 

.rawb0tsApp__mint-numbers {
   display: flex;
   flex-wrap: wrap;
   max-width: 260px;
}

.rawb0tsApp_mint-numbers_available,
.rawb0tsApp_mint-numbers_unavailable {
  display: flex;
  flex-direction: row;
  padding-top: 1.5rem;
  align-items: center;
}

.rawb0tsApp_mint-numbers_available img,
.rawb0tsApp_mint-numbers_unavailable img {
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
  }

.rawb0tsApp_mint-numbers_available p,
.rawb0tsApp_mint-numbers_unavailable p {
  font-weight: 500;
  font-family: 'Rajdhani', sans-serif !important;
  text-transform: uppercase;
  font-size: 18px !important;
  text-rendering: optimizeLegibility;
}

.rawb0tsApp_mint-numbers_available p {
  color: var(--mint-available) !important;
}

.rawb0tsApp_mint-numbers_unavailable p {
  color: var(--mint-unavailable) !important;
}

.rawb0tsApp__mint-numbers button {
  margin: 2rem 0rem;
  color: #fff;
  padding: 1rem 2rem;
  font-family: 'Rajdhani', sans-serif;
  font-size: 38px;
  border-width: 0;
  border-radius: 5px;
  min-width: 520px;
  z-index: 1;
  cursor: pointer;
  flex-wrap: nowrap;

 } 

.rawb0tsApp__mint-numbers button:hover {
  color: #fff;
}

 .rawb0tsApp__mint-numbers h2 {
  color: var(--hp);
  font-size: 80px;
  line-height: 65px;
}

.rawb0tsApp__mint-numbers p {
color: #fff;
font-family: 'Epilogue', sans-serif;
line-height: 1.4rem;
font-size: 15px;
}

.rawb0tsApp__mint_contents {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.rawb0tsApp__mint-box_info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 3rem;
  z-index: 1;
  background: #312a3e;
}

.rawb0tsApp__mint-box_info p {
  color: #fff;
  font-family: 'Epilogue', sans-serif;
  line-height: 1.4rem;
  font-size: 15px;
}

.rawb0tsApp__mint-box_lootbox img{
  width: 553px;
  display: flex;
  justify-content: flex-end;
  min-width: 100px;
  -ms-interpolation-mode: nearest-neighbor;
      image-rendering: -moz-crisp-edges;
      image-rendering: pixelated;
  margin-top: -100px;
}

.rawb0tsApp_mint-box_gif img {
  max-width: 320px;
  display: flex;
  -ms-interpolation-mode: nearest-neighbor;
      image-rendering: -moz-crisp-edges;
      image-rendering: pixelated;
}

.rawb0tsApp_mint-box_gif-and-info {
  display: flex;
  flex-direction: row;
  max-width: 980px;
}

.rawb0tsApp_mint-box_purpleTriangle {
  display: flex;
  justify-content: flex-end;
  padding-right: 8rem;
}

.rawb0tsApp_mint-box_purpleTriangle img {
  width: 88px;
  height: 44px;
  margin-top: 3rem;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 880px) {
  .rawb0tsApp__mint {
    padding: 9rem 1rem 1rem 1rem;
    min-height: 100vh;
    margin-top: -6rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .rawb0tsApp__mint-box {
    margin: 1.5rem;
    padding: 3rem;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .rawb0tsApp__mint_contents {
    flex-direction: column-reverse;
  }

  .rawb0tsApp__mint-box_lootbox img{
    display: flex;
    flex-wrap: nowrap;
    max-width: 100%;
  }

  .rawb0tsApp__mint-numbers {
   display: flex;
   flex-wrap: wrap;
   max-width: 400px;
  }

   .rawb0tsApp__mint-numbers h2 {
    color: var(--hp);
    font-size: 60px;
    line-height: 45px;
  }

  .rawb0tsApp__mint-numbers h3 {
    display: flex;
    min-width: 100%;
  }

  .rawb0tsApp_mint-box_gif-and-info {
    display: flex;
    margin: 0rem 1.5rem 1.5rem 1.5rem;
    flex-direction: column-reverse;
  }

  .rawb0tsApp_mint-box_gif img {
    display: flex;
    max-width: 100%;
  }

  .rawb0tsApp__mint-box_info {
    display: flex;
    max-width: 100%;
  }

  .rawb0tsApp_mint-box_purpleTriangle img {
      margin-top: 0rem;
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
  }

  .rawb0tsApp__mint-numbers button {
    margin: 2rem 0rem;
    color: #fff;
    padding: 1rem 2rem;
    font-family: 'Rajdhani', sans-serif;
    font-size: 28px;
    border-width: 0;
    border-radius: 5px;
    min-width: 320px;
    z-index: 1;
    cursor: pointer;
    flex-wrap: wrap;
  
   } 

}
.rawb0tsApp__bands {
  line-height: 1rem;
  color: var(--p-default);
  font-size: 13px;
}

.rawb0tsApp__bands-singleband {
  display: flex;
  justify-content: flex-start;
}

.rawb0tsApp__bands-singleband p {
  min-width: 2rem;
  display: flex;
  justify-content: flex-end;
}

.rawb0tsApp__bands-singleband_band {
  display: flex;
  height: 8px;
  width: 50px;
  margin-top: 3px;
  margin-left: 5px;
  background: var(--button-utility-bg);
}

.rawb0tsApp__bands-singleband_band_hp {
  background: var(--hp);
  height: 8px;
  width: 30px; /* MODIFICARE LARGHEZZA A SECONDA DELLA STATISTICA - LA BANDA DI SFONDO è LARGA 50px QUINDI BISOGNA FARE UNA PROPORZIONE... SE TI VIENE IN MENTE ALTRO FAI PURE*/
}

.rawb0tsApp__bands-singleband_band_pow {
  background: var(--pow);
  height: 8px;
  width: 20px;
}

.rawb0tsApp__bands-singleband_band_en {
  background: var(--en);
  height: 8px;
  width: 14px;
}

.rawb0tsApp__bands-singleband_band_arm {
  background: var(--arm);
  height: 8px;
  width: 10px;
}

.rawb0tsApp__bands-singleband_band_sth {
  background: var(--sth);
  height: 8px;
  width: 5px;
}

.rawb0tsApp__bands-singleband_band_mad {
  background: var(--mad);
  height: 8px;
  width: 34px;
}

.rawb0tsApp__bands-singleband_band_cr {
  background: var(--cr);
  height: 8px;
  width: 20px;
}
.rawb0tsApp__armory {
  
  background: var(--gradient-bg-dark);
  padding: 2rem 1rem;
  border-bottom: #000 solid 15px;
  padding: 3rem;
}

.rawb0tsApp__singlebot-container {
  border-bottom: 0px !important;
}

h3 {
  color: var(--myrawb0ts-toptitle);
}

h4 {
  color: #fff;
  font-size: 1.1rem;
  text-transform: uppercase;
  line-height: 1rem;
  margin-bottom: -5px;
}

.rawb0tsApp_armory-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: -5px;
}

.rawb0tsApp_armory-title {
  margin-bottom: 1rem;
}

.rawb0tsApp_armory-text {
  padding: 3rem 5rem;
  margin-top: -2rem;
}

.rawb0tsApp_armory-text p {
  font-family: var(--font-family2);
  color: #fff;
  font-size: 0.9rem;
  line-height: 1.4rem;
  margin-bottom: -5px;
}

.rawb0tsApp_armory-bots {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background: var(--myrawb0ts-armory-bots-bg);
  padding: 2rem 1rem;
  border-radius: 5px;
}

.rawb0tsApp_armory-bots_1stColumn,
.rawb0tsApp_armory-bots_2ndColumn,
.rawb0tsApp_armory-bots_3rdColumn {
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 10px;
  align-self: flex-start;
}

.rawb0tsApp_armory-bots_items {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  align-items: center;
  padding-bottom: 21px;
  -ms-interpolation-mode: nearest-neighbor;
      image-rendering: -moz-crisp-edges;
      image-rendering: pixelated;
}

.rawb0tsApp_armory-bots_items img:hover {
  -webkit-animation: glow 0.5s forwards;
          animation: glow 0.5s forwards;
}

@-webkit-keyframes glow {
  from {
    box-shadow: 0 0 5px -5px var(--common-rarity);
  }
  to {
    box-shadow: 0 0 5px 5px var(--common-rarity);
  }
}

@keyframes glow {
  from {
    box-shadow: 0 0 5px -5px var(--common-rarity);
  }
  to {
    box-shadow: 0 0 5px 5px var(--common-rarity);
  }
}

.inverted {
  flex-direction: row-reverse;
}

.rawb0tsApp_armory-bots_items p {
  color: var(--p-default);
  padding-top: 2px;
}

.text-right {
  text-align: left;
  padding-left: 10px;
}

.text-left {
  text-align: right;
  padding-right: 10px;
}


.rawb0tsApp__singlebot {
  width: 200px;
  margin-bottom: 2.5rem;
  margin-right: 2rem;
  min-width: 200px;
}

.rawb0tsApp__singlebot-nomargins {
  margin-bottom: 0px;
  margin-right: 0px;
}

a {
  cursor: pointer;
}

.Modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  min-width: 900px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--myrawb0ts-modal-overlay-bg);
  z-index: 998;
}

.closebutton {
  width: 30px;
  height: 30px;
  margin-bottom: -70px;
  margin-right: 60px;
  align-self: flex-end;
  cursor: pointer;
  z-index: 4;
}

@media screen and (max-width: 550px) {
  .rawb0tsApp__singlebot {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    min-width: 200px;
  }
}

.rawb0tsApp__singlebot-container {
  background: var(--color-bg);
  border-bottom: solid #000 15px;
}

.rawb0tsApp__singlebot-header {
  padding: 0.55rem 0.55rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}

.rawb0tsApp__singlebot-header-title {
  display: flex;
  flex-direction: column;
  line-height: 20px;
  margin-top: 4px;
  margin-left: 3px;
}

.rawb0tsApp__singlebot-header-rarity {
  padding: 0.15rem 0.5rem 0.05rem;
  color: #fff;
  border-radius: 3px;
  border: none;
  display: flex;
  justify-content: flex-end;
  align-content: flex-start;
  align-items: flex-start;
  background: #ff4400;
  margin-top: -15px;
  margin-right: -20px;
}

.rawb0tsApp__singlebot-image {
  width: 200px;
  height: 200px;
  display: flex;
  -ms-interpolation-mode: nearest-neighbor;
      image-rendering: -moz-crisp-edges;
      image-rendering: pixelated;
}

#bot-number {
  color: #fff;
  font-size: 24px;
}

#gen {
  color: var(--p-default);
}

.rawb0tsApp_singlebot-bottom {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  border-bottom: #000 solid 15px;
}

.rawb0tsApp__singlebot-utility {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: -5px;
}

.rawb0tsApp__singlebot-utility_cooldown {
  display: flex;
  padding: 0.7rem 0.5rem 0rem 0.6rem;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.rawb0tsApp__singlebot-utility_cooldown p {
  color: var(--p-default);
}

.rawb0tsApp__singlebot-utility_cooldown img {
  width: 33px;
  height: 33px;
  margin-right: 8px;
}

.rawb0tsApp_singlebot-utility_messages {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
}

.rawb0tsApp_singlebot-utility_messages p { 
  padding: 0.1rem;
  margin-left: 10px;
  margin-bottom: 10px;
  max-width: 90px;
  color: #fff;
  font-family: var(--font-family);
  font-size: 15px;
  text-transform: uppercase;
  background: var(--button-utility-bg);
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  text-align: center;
}

#ready {
  color: var(--button-bg);
}

#pla {
  color: var(--p-default);
  font-size: 0.8rem;
  background: none;
  border: 1px solid var(--p-default);
}

.rawb0tsApp_singlebot-utility_bands {
  padding: 0.55rem 0rem 0.5rem 0rem;
  display: flex;
  justify-content: flex-end;

}

.rawb0tsApp_singlebot-options {
  padding: 0.4rem 0rem;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
}

#opensea-link {
  color: var(--opensea);
}

.flask_0 {
  object-fit: cover;
  object-position: 0 0; /*a seconda del cooldown basterà cambiare la className della flask, flask_0 = vuota, flask_33 = piena al 33%, e via cosi. A seconda del colore bisogna cambiare l'immagine dentro a src={flask#colore}*/
}

.flask_33 {
  object-fit: cover;
  object-position: 33% 0;
}

.flask_66 {
  object-fit: cover;
  object-position: 66% 0;
}

.flask_100 {
  object-fit: cover;
  object-position: 100% 0;
}
.rawb0tsApp__footer {
  display: flex;
  justify-self: center;
  justify-content: center;
  padding: 2rem 0rem 1rem 0rem;
  color: rgba(0, 0, 0, 0.3);
  min-width: 100%;
}

.rawb0tsApp__myrawb0ts {
  overflow: hidden;
  background: var(--myrawb0ts-bg);
  min-height: 100vh;
  margin-top: -6rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:flex-start;
  align-items:flex-start;
}

.rawb0tsApp__myrawb0ts-bg {
  min-height: 100vh;
  width: 100%;
  padding: 11rem 2rem 3rem 6rem;
  background-blend-mode: overlay;
  background-image: url(../../static/media/hexagon_BG01.beb1c918.png);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
}

@media screen and (max-width: 550px) {
  .rawb0tsApp__myrawb0ts-bg{
    padding: 8rem 2rem;
  }
}

.rawb0tsApp__myrawb0ts-info {
  background: var(--myrawb0ts-info-bg);
  max-width: 400px;
  padding: 3rem;
  border-bottom: solid #000 15px;
}

.rawb0tsApp__myrawb0ts-info p {
  color: #fff;
  font-family: 'Epilogue', sans-serif;
  line-height: 1.4rem;
  font-size: 15px;
  letter-spacing: 0.05rem;
}

.rawb0tsApp__myrawb0ts-info h3 {
  color: var(--myrawb0ts-toptitle);
}





html,
body {
  margin: 0;
  padding: 0;
}

.App {
  height: 100vh;
}

canvas {
  height: calc(100vh - 6rem) !important;
}

.rawb0tsApp_WarVerse_home_welcome {
  position: relative;
  top: 4rem;
  left: 4rem;
  right: 4rem;
  padding: 2rem;
  background-color: var(--color-bg);
  border-radius: 5px;
  color: #fff;
  width: 350px;

}

.rawb0tsApp_WarVerse_home_welcome p {
  color: #fff;
  font-family: 'Epilogue', sans-serif;
  line-height: 1.4rem;
  font-size: 15px;
  letter-spacing: 0.05rem;
  padding-bottom: 1rem;
}

.rawb0tsApp_WarVerse-planet_menu {
  margin-left: 5rem;
  display: flex;
  flex-direction: column;
}

.rawb0tsApp_WarVerse-planet_menu-buttons {
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  margin-top: -20.5rem;
  padding-bottom: 0.5rem;
}

.planet-menu_item {
  width: 10rem;
  height: 20rem;
  background: #333;
  color: #fff;
  display: flex;
  justify-self: center;
  justify-content: center;
  margin-right: 0.5rem;
  padding: 1.4rem;
  cursor: pointer;
  align-items: flex-end;
}

.hide {
  display: none;
}

.planet-menu_mint {
  -ms-interpolation-mode: nearest-neighbor;
      image-rendering: -moz-crisp-edges;
      image-rendering: pixelated;
  background-image: url(../../static/media/earth_mint_menu.6cdf13ed.jpg); 
  background-size: cover;
  background-color: #000;
}

.planet-menu_mint:hover {
  background-image: url(../../static/media/earth_mint_hover_menu.856628ee.jpg);
  border-bottom: var(--menu-hover) solid 15px;
  color: var(--menu-hover);
  padding-bottom: calc(1.4rem - 15px);
  }

.planet-menu_myrawb0ts {
  -ms-interpolation-mode: nearest-neighbor;
      image-rendering: -moz-crisp-edges;
      image-rendering: pixelated;
  background-image: url(../../static/media/earth_myrawb0ts_menu.f56670dc.jpg);
  background-size: cover;
  background-color: #000;
}

.planet-menu_myrawb0ts:hover {
  border-bottom: var(--menu-hover) solid 15px;
  color: var(--menu-hover);
  padding-bottom: calc(1.4rem - 15px);
}

.planet-menu_warverse {
  -ms-interpolation-mode: nearest-neighbor;
      image-rendering: -moz-crisp-edges;
      image-rendering: pixelated;
  background-image: url(../../static/media/earth_warverse_menu.1d6fef49.jpg);
  background-size: cover;
  background-color: #000;
}

.planet-menu_warverse:hover {
  background-blend-mode: luminosity, normal;
}

.rawb0tsApp__WarVerse-universe_tooltip {
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  font-size: 14px;
  min-width: 10rem;
  max-width: 10rem;
  background: var(--warverse-tooltip);
  color: #fff;
  padding: 5px 0rem;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
}

.moon {
  -webkit-transform: translate3d(10%, 0, 0);
          transform: translate3d(10%, 0, 0);
  background: #333333;
}

.rawb0tsApp__WarVerse_home-settings {
  position: absolute;
  width: 100vw;
  top: calc(100vh - 8rem);
  color: #333;
  display: flex;
  align-items: center;
  padding: 0px 0px 20px 15px;
  background: transparent;
  justify-content: space-between;
}

.rawb0tsApp__WarVerse_home-settings p {
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.rawb0tsApp__WarVerse_home-settings input {
  margin-left: 6px;
  width: 12px;
  border-width: 0px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
  min-height: 100vh;
}

.content {
  overflow: hidden;
  z-index: -10;
}

h1 {
  font-size: 60px;
  color: #fff;
}
