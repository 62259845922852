.rawb0tsApp__bands {
  line-height: 1rem;
  color: var(--p-default);
  font-size: 13px;
}

.rawb0tsApp__bands-singleband {
  display: flex;
  justify-content: flex-start;
}

.rawb0tsApp__bands-singleband p {
  min-width: 2rem;
  display: flex;
  justify-content: flex-end;
}

.rawb0tsApp__bands-singleband_band {
  display: flex;
  height: 8px;
  width: 50px;
  margin-top: 3px;
  margin-left: 5px;
  background: var(--button-utility-bg);
}

.rawb0tsApp__bands-singleband_band_hp {
  background: var(--hp);
  height: 8px;
  width: 30px; /* MODIFICARE LARGHEZZA A SECONDA DELLA STATISTICA - LA BANDA DI SFONDO è LARGA 50px QUINDI BISOGNA FARE UNA PROPORZIONE... SE TI VIENE IN MENTE ALTRO FAI PURE*/
}

.rawb0tsApp__bands-singleband_band_pow {
  background: var(--pow);
  height: 8px;
  width: 20px;
}

.rawb0tsApp__bands-singleband_band_en {
  background: var(--en);
  height: 8px;
  width: 14px;
}

.rawb0tsApp__bands-singleband_band_arm {
  background: var(--arm);
  height: 8px;
  width: 10px;
}

.rawb0tsApp__bands-singleband_band_sth {
  background: var(--sth);
  height: 8px;
  width: 5px;
}

.rawb0tsApp__bands-singleband_band_mad {
  background: var(--mad);
  height: 8px;
  width: 34px;
}

.rawb0tsApp__bands-singleband_band_cr {
  background: var(--cr);
  height: 8px;
  width: 20px;
}