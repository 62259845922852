.rawb0tsApp__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--gradient-bg-dark);
  padding: 1rem 6rem;
  z-index: 999;
}

.rawb0tsApp__navbar-links {
flex: 1;
display: flex;
justify-content: flex-start;
align-items: center;
}

.rawb0tsApp__navbar-links_logo {
  margin-right: 2rem;
  margin-top: 10px;
}

.rawb0tsApp__navbar-links_logo img {
  width: 126px;
  height: 40px;
}

.rawb0tsApp__navbar-links_container {
  display: flex;
  flex-direction: row;
}

.rawb0tsApp__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.rawb0tsApp__navbar-links_container p,
.rawb0tsApp__navbar-menu_container p {
  color: #fff;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  margin: 0 1rem;
  cursor: pointer;
}

.rawb0tsApp__navbar-links_container p:hover {
  color: #BF2651;
  border-bottom:5px solid #bf2651;
  padding-bottom: 5px;
  margin-bottom: -10px;
}

.rawb0tsApp__navbar-sign p {
  color: #fff;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  text-transform: uppercase;
  margin: 0 1rem;
  cursor: pointer;
}

.rawb0tsApp__navbar-sign button,
.rawb0tsApp__navbar-menu_container-links_sign button {
  padding: 1rem 2rem;
  margin-left: 0rem;
  color: #fff;
  font-family: var(--font-family);
  font-size: 18px;
  text-transform: uppercase;
  background: var(--button-bg);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  min-width: 230px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}

.rawb0tsApp__navbar-sign button:hover,
.rawb0tsApp__navbar-menu_container-links_sign button:hover {
  background: var(--button-bg-hover);
}

.rawb0tsApp__navbar-sign button img,
.rawb0tsApp__navbar-menu_container-links_sign button img {
  width: 32px;
  height: 32px;
  margin-right: 1rem;
}

.rawb0tsApp__navbar-sign button p,
.rawb0tsApp__navbar-menu_container-links_sign button p {
  padding: 0rem 0rem;
  font-size: 18px;
}

.rawb0tsApp__navbar-menu {
  margin-left: 0rem;
  display: none;
  position: relative;
}

.rawb0tsApp__navbar-menu svg {
  cursor: pointer;
  margin-left: 1rem;
}

.rawb0tsApp__navbar-menu_container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  text-align: start;
  background: var(--navbar-bg);
  padding: 1.5rem 3rem;
  position: absolute;
  top: 3rem;
  right: -10px;
  min-width: 350px;
  border-radius: 5px;
  z-index: 01;
}

.rawb0tsApp__navbar-menu_container p {
  margin: 0.7rem 0;
}

.rawb0tsApp__navbar-menu_container a:hover {
  color: #BF2651;
  border-right:0.35rem solid #bf2651;
  padding-right: 0.5rem;
  margin-right: -0.85rem;
}

.rawb0tsApp__navbar-menu_container-links {
  max-width: max-content;
}

.rawb0tsApp__navbar-menu_container-links_sign {
  display: none;
}

@media screen and (max-width: 1050px) {

  .rawb0tsApp__navbar-menu_container-links p:hover {
    color: #BF2651;
    border-right:5px solid #bf2651;
    padding-right: 5px;
    margin-right: -10px;
  }

  .rawb0tsApp__navbar-links_container {
    display: none;
  }

  .rawb0tsApp__navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .rawb0tsApp__navbar-sign,
  .rawb0tsApp__navbar-links_container
   {
    display: none;
  }

  .rawb0tsApp__navbar-menu,
  .rawb0tsApp__navbar-menu_container-links_sign {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  }

  .rawb0tsApp__navbar-menu_container-links_sign p:hover {
    color: #fff;
    border: 0px;
    padding-right: 10px;
  }

  .rawb0tsApp__navbar {
    padding: 1rem 2rem;
  }
}

@media screen and (max-width: 500px) {
  .rawb0tsApp__navbar {
    padding: 1rem 2rem;
  }

  .rawb0tsApp__navbar-sign {
    display: none;
  }

  .rawb0tsApp__navbar-menu_container-links_sign {
    margin-top: 1rem;
    display: block;
  }

}