@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@300&display=swap');

:root {
--font-family2: Epilogue, sans-serif;
font-family: 'Rajdhani', sans-serif;

--hp: #78B392;
--pow: #F54F4F;
--en: #FF9200;
--arm: #728794;
--sth: #000;
--mad: #BF2651;
--cr: #4D6A94;

--common-rarity: #78B392;

--myrawb0ts-bg: linear-gradient(180deg, #357985 0%, #405578 100%);
--mint-bg: radial-gradient(circle at 50% 30%, #728794 0%, #3D3957 60%);

--myrawb0ts-info-bg: #303D50;
--mint-info-bg: #524a63;
--myrawb0ts-modal-bg: #303D50;
--myrawb0ts-modal-overlay-bg: #1f2835e8;
--myrawb0ts-armory-bots-bg: #1a1c2b50;

--myrawb0ts-toptitle: #BF2651;
--mint-toptitle: #ff9200;

--coming-soon: #555;
--mint-unavailable: rgb(255, 0, 0);
--mint-available: rgb(9, 255, 0);
--tooltip-bg: #222;
--unavailable-color: #333;
--warverse-tooltip: #4c8f82;
--universe-grid: #1A1C2B;
--menu-hover: #BF2651;

--opensea: #99c2db;

--p-default: #728794;
--color-bg: #303D50;
--navbar-bg: #091b3c;
--menu-bg: #000;
--button-bg-hover: #78b392;
--button-bg: #4c8f82;
--button-utility-bg: #405578;
--gradient-bg: linear-gradient(180deg, #357985 0%, #405578 100%);
--gradient-bg-dark: linear-gradient(160deg, #00051D 0%, #091b3c 50%); /*linear-gradient(177deg, #303D50 0%, rgba(26, 28, 43, 1) 80%);*/
--gradient-bg-armory: linear-gradient(177deg, #303D50 0%, #1a1c2b 80%);
}

a {
  color: unset;
  text-decoration: none;
}

h2 {
  font-size: 64px;
  color: #fff;
  line-height: 58px;
  padding-bottom: 20px;
}

.default__bg {
  background: #000;
}

.color-change-2x {
  background: var(--button-bg);
}

.color-change-2x:hover {
	-webkit-animation: color-change-2x 1s linear infinite alternate both;
	        animation: color-change-2x 1s linear infinite alternate both;
}

 @-webkit-keyframes color-change-2x {
  0% {
    background: #4c8f82;
  }
  50% {
    background: #ff9200;
  }
  100% {
    background: #BF2651;
  }
}
@keyframes color-change-2x {
  0% {
    background: #4c8f82;
  }
  50% {
    background: #ff9200;
  }
  100% {
    background: #BF2651;
  }
}


.gradient__bg
{
    /* ff 3.6+ */
    background:-moz-linear-gradient(180deg, rgba(53, 121, 133, 1) 0%, rgba(64, 85, 120, 1) 100%); 

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(180deg, rgba(53, 121, 133, 1) 0%, rgba(64, 85, 120, 1) 100%);

    /* opera 11.10+ */
    background:-o-linear-gradient(180deg, rgba(53, 121, 133, 1) 0%, rgba(64, 85, 120, 1) 100%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#405578', endColorstr='#357985', GradientType=1 );

    /* ie 10+ */
    background:-ms-linear-gradient(180deg, rgba(53, 121, 133, 1) 0%, rgba(64, 85, 120, 1) 100%);

    /* global 94%+ browsers support */
    background:linear-gradient(180deg, rgba(53, 121, 133, 1) 0%, rgba(64, 85, 120, 1) 100%);
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.slide-bottom {
	-webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

 @-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-300px);
            transform: translateY(-300px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-300px);
            transform: translateY(-300px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

.slide-in-blurred-top {
	-webkit-animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	        animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

 @-webkit-keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
            transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
            transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

.slit-out-vertical {
	-webkit-animation: slit-out-vertical 0.5s ease-in both;
	        animation: slit-out-vertical 0.5s ease-in both;
}

 @-webkit-keyframes slit-out-vertical {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
    opacity: 1;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
            transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
            transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
}
@keyframes slit-out-vertical {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
    opacity: 1;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
            transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
            transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
}

.slide-out-blurred-top {
	-webkit-animation: slide-out-blurred-top 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
	        animation: slide-out-blurred-top 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
}

 @-webkit-keyframes slide-out-blurred-top {
  0% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
            transform: translateY(-1000px) scaleY(2) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}
@keyframes slide-out-blurred-top {
  0% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
            transform: translateY(-1000px) scaleY(2) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}

.fade-in {
	-webkit-animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.flip-vertical-left {
	-webkit-animation: flip-vertical-left 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: flip-vertical-left 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

@-webkit-keyframes flip-vertical-left {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
  }
}
@keyframes flip-vertical-left {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
  }
}



@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }
  
  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }
  
  .section__margin {
    margin: 4rem 2rem;
  }
}

.unavailable p {
  color: var(--unavailable-color) !important;
}

.unavailable p:hover {
  border: 0px !important;
}

/* START TOOLTIP STYLES */
[tooltip] {
  position: relative; /* opinion 1 */
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  font-family: 'Rajdhani', sans-serif !important;
  text-transform: uppercase; /* opinion 2 */
  font-size: .9em; /* opinion 3 */
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  margin-bottom: -5px;
  display: none;
  opacity: 0;
  text-rendering: optimizeLegibility;
}
[tooltip]::before {
  content: '';
  border: 0px solid #000; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */

}
[tooltip]::after {
  content: attr(tooltip); /* magic! */
  
  /* most of the rest of this is opinion */
  font-family: Helvetica, sans-serif;
  text-align: center;
  
  /* 
    Let the content set the size of the tooltips 
    but this will also keep them from being obnoxious
    */
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: .3ch;
  box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.259);
  background: var(--tooltip-bg);
  color: #fff;
  z-index: 1000; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;

}

/* don't show empty tooltips */
[tooltip='']::before,
[tooltip='']::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: rgb(0, 0, 0);
}
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -.5em);
}

/* FLOW: DOWN */
[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: rgb(0, 0, 0);
}
[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}
[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, .5em);
}

/* FLOW: LEFT */
[tooltip][flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: rgb(0, 0, 0);
  left: calc(0em - 5px);
  transform: translate(-.5em, -50%);
}
[tooltip][flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-.5em, -50%);
}

/* FLOW: RIGHT */
[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: rgb(0, 0, 0);
  right: calc(0em - 5px);
  transform: translate(.5em, -50%);
}
[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(.5em, -50%);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: .9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: .9;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */ 
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}