html,
body {
  margin: 0;
  padding: 0;
}

.App {
  height: 100vh;
}

canvas {
  height: calc(100vh - 6rem) !important;
}

.rawb0tsApp_WarVerse_home_welcome {
  position: relative;
  top: 4rem;
  left: 4rem;
  right: 4rem;
  padding: 2rem;
  background-color: var(--color-bg);
  border-radius: 5px;
  color: #fff;
  width: 350px;

}

.rawb0tsApp_WarVerse_home_welcome p {
  color: #fff;
  font-family: 'Epilogue', sans-serif;
  line-height: 1.4rem;
  font-size: 15px;
  letter-spacing: 0.05rem;
  padding-bottom: 1rem;
}

.rawb0tsApp_WarVerse-planet_menu {
  margin-left: 5rem;
  display: flex;
  flex-direction: column;
}

.rawb0tsApp_WarVerse-planet_menu-buttons {
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  margin-top: -20.5rem;
  padding-bottom: 0.5rem;
}

.planet-menu_item {
  width: 10rem;
  height: 20rem;
  background: #333;
  color: #fff;
  display: flex;
  justify-self: center;
  justify-content: center;
  margin-right: 0.5rem;
  padding: 1.4rem;
  cursor: pointer;
  align-items: flex-end;
}

.hide {
  display: none;
}

.planet-menu_mint {
  image-rendering: pixelated;
  background-image: url(../../assets/images/earth_mint_menu.jpg); 
  background-size: cover;
  background-color: #000;
}

.planet-menu_mint:hover {
  background-image: url(../../assets/images/earth_mint_hover_menu.jpg);
  border-bottom: var(--menu-hover) solid 15px;
  color: var(--menu-hover);
  padding-bottom: calc(1.4rem - 15px);
  }

.planet-menu_myrawb0ts {
  image-rendering: pixelated;
  background-image: url(../../assets/images/earth_myrawb0ts_menu.jpg);
  background-size: cover;
  background-color: #000;
}

.planet-menu_myrawb0ts:hover {
  border-bottom: var(--menu-hover) solid 15px;
  color: var(--menu-hover);
  padding-bottom: calc(1.4rem - 15px);
}

.planet-menu_warverse {
  image-rendering: pixelated;
  background-image: url(../../assets/images/earth_warverse_menu.jpg);
  background-size: cover;
  background-color: #000;
}

.planet-menu_warverse:hover {
  background-blend-mode: luminosity, normal;
}

.rawb0tsApp__WarVerse-universe_tooltip {
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  font-size: 14px;
  min-width: 10rem;
  max-width: 10rem;
  background: var(--warverse-tooltip);
  color: #fff;
  padding: 5px 0rem;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
}

.moon {
  transform: translate3d(10%, 0, 0);
  background: #333333;
}

.rawb0tsApp__WarVerse_home-settings {
  position: absolute;
  width: 100vw;
  top: calc(100vh - 8rem);
  color: #333;
  display: flex;
  align-items: center;
  padding: 0px 0px 20px 15px;
  background: transparent;
  justify-content: space-between;
}

.rawb0tsApp__WarVerse_home-settings p {
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.rawb0tsApp__WarVerse_home-settings input {
  margin-left: 6px;
  width: 12px;
  border-width: 0px;
}
