.rawb0tsApp__myrawb0ts {
  overflow: hidden;
  background: var(--myrawb0ts-bg);
  min-height: 100vh;
  margin-top: -6rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:flex-start;
  align-items:flex-start;
}

.rawb0tsApp__myrawb0ts-bg {
  min-height: 100vh;
  width: 100%;
  padding: 11rem 2rem 3rem 6rem;
  background-blend-mode: overlay;
  background-image: url('../../assets/images/hexagon_BG01.png');
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
}

@media screen and (max-width: 550px) {
  .rawb0tsApp__myrawb0ts-bg{
    padding: 8rem 2rem;
  }
}

.rawb0tsApp__myrawb0ts-info {
  background: var(--myrawb0ts-info-bg);
  max-width: 400px;
  padding: 3rem;
  border-bottom: solid #000 15px;
}

.rawb0tsApp__myrawb0ts-info p {
  color: #fff;
  font-family: 'Epilogue', sans-serif;
  line-height: 1.4rem;
  font-size: 15px;
  letter-spacing: 0.05rem;
}

.rawb0tsApp__myrawb0ts-info h3 {
  color: var(--myrawb0ts-toptitle);
}



