.rawb0tsApp__singlebot {
  width: 200px;
  margin-bottom: 2.5rem;
  margin-right: 2rem;
  min-width: 200px;
}

.rawb0tsApp__singlebot-nomargins {
  margin-bottom: 0px;
  margin-right: 0px;
}

a {
  cursor: pointer;
}

.Modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 900px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--myrawb0ts-modal-overlay-bg);
  z-index: 998;
}

.closebutton {
  width: 30px;
  height: 30px;
  margin-bottom: -70px;
  margin-right: 60px;
  align-self: flex-end;
  cursor: pointer;
  z-index: 4;
}

@media screen and (max-width: 550px) {
  .rawb0tsApp__singlebot {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    min-width: 200px;
  }
}

.rawb0tsApp__singlebot-container {
  background: var(--color-bg);
  border-bottom: solid #000 15px;
}

.rawb0tsApp__singlebot-header {
  padding: 0.55rem 0.55rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}

.rawb0tsApp__singlebot-header-title {
  display: flex;
  flex-direction: column;
  line-height: 20px;
  margin-top: 4px;
  margin-left: 3px;
}

.rawb0tsApp__singlebot-header-rarity {
  padding: 0.15rem 0.5rem 0.05rem;
  color: #fff;
  border-radius: 3px;
  border: none;
  display: flex;
  justify-content: flex-end;
  align-content: flex-start;
  align-items: flex-start;
  background: #ff4400;
  margin-top: -15px;
  margin-right: -20px;
}

.rawb0tsApp__singlebot-image {
  width: 200px;
  height: 200px;
  display: flex;
  image-rendering: pixelated;
}

#bot-number {
  color: #fff;
  font-size: 24px;
}

#gen {
  color: var(--p-default);
}

.rawb0tsApp_singlebot-bottom {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  border-bottom: #000 solid 15px;
}

.rawb0tsApp__singlebot-utility {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: -5px;
}

.rawb0tsApp__singlebot-utility_cooldown {
  display: flex;
  padding: 0.7rem 0.5rem 0rem 0.6rem;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.rawb0tsApp__singlebot-utility_cooldown p {
  color: var(--p-default);
}

.rawb0tsApp__singlebot-utility_cooldown img {
  width: 33px;
  height: 33px;
  margin-right: 8px;
}

.rawb0tsApp_singlebot-utility_messages {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
}

.rawb0tsApp_singlebot-utility_messages p { 
  padding: 0.1rem;
  margin-left: 10px;
  margin-bottom: 10px;
  max-width: 90px;
  color: #fff;
  font-family: var(--font-family);
  font-size: 15px;
  text-transform: uppercase;
  background: var(--button-utility-bg);
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  text-align: center;
}

#ready {
  color: var(--button-bg);
}

#pla {
  color: var(--p-default);
  font-size: 0.8rem;
  background: none;
  border: 1px solid var(--p-default);
}

.rawb0tsApp_singlebot-utility_bands {
  padding: 0.55rem 0rem 0.5rem 0rem;
  display: flex;
  justify-content: flex-end;

}

.rawb0tsApp_singlebot-options {
  padding: 0.4rem 0rem;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
}

#opensea-link {
  color: var(--opensea);
}

.flask_0 {
  object-fit: cover;
  object-position: 0 0; /*a seconda del cooldown basterà cambiare la className della flask, flask_0 = vuota, flask_33 = piena al 33%, e via cosi. A seconda del colore bisogna cambiare l'immagine dentro a src={flask#colore}*/
}

.flask_33 {
  object-fit: cover;
  object-position: 33% 0;
}

.flask_66 {
  object-fit: cover;
  object-position: 66% 0;
}

.flask_100 {
  object-fit: cover;
  object-position: 100% 0;
}