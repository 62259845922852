.rawb0tsApp__armory {
  
  background: var(--gradient-bg-dark);
  padding: 2rem 1rem;
  border-bottom: #000 solid 15px;
  padding: 3rem;
}

.rawb0tsApp__singlebot-container {
  border-bottom: 0px !important;
}

h3 {
  color: var(--myrawb0ts-toptitle);
}

h4 {
  color: #fff;
  font-size: 1.1rem;
  text-transform: uppercase;
  line-height: 1rem;
  margin-bottom: -5px;
}

.rawb0tsApp_armory-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: -5px;
}

.rawb0tsApp_armory-title {
  margin-bottom: 1rem;
}

.rawb0tsApp_armory-text {
  padding: 3rem 5rem;
  margin-top: -2rem;
}

.rawb0tsApp_armory-text p {
  font-family: var(--font-family2);
  color: #fff;
  font-size: 0.9rem;
  line-height: 1.4rem;
  margin-bottom: -5px;
}

.rawb0tsApp_armory-bots {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background: var(--myrawb0ts-armory-bots-bg);
  padding: 2rem 1rem;
  border-radius: 5px;
}

.rawb0tsApp_armory-bots_1stColumn,
.rawb0tsApp_armory-bots_2ndColumn,
.rawb0tsApp_armory-bots_3rdColumn {
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 10px;
  align-self: flex-start;
}

.rawb0tsApp_armory-bots_items {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  align-items: center;
  padding-bottom: 21px;
  image-rendering: pixelated;
}

.rawb0tsApp_armory-bots_items img:hover {
  animation: glow 0.5s forwards;
}

@keyframes glow {
  from {
    box-shadow: 0 0 5px -5px var(--common-rarity);
  }
  to {
    box-shadow: 0 0 5px 5px var(--common-rarity);
  }
}

.inverted {
  flex-direction: row-reverse;
}

.rawb0tsApp_armory-bots_items p {
  color: var(--p-default);
  padding-top: 2px;
}

.text-right {
  text-align: left;
  padding-left: 10px;
}

.text-left {
  text-align: right;
  padding-right: 10px;
}

