/*.rawb0tsApp__mint {
  background: var(--mint-bg);
  overflow: hidden;
  min-height: 100vh;
  margin-top: -6rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.rawb0tsApp__mint {
  padding: 11rem 3rem 0rem 3rem;
  background-image: url('../../assets/images/mint_BG01.png');
  background-blend-mode: overlay;
  overflow: hidden;
  min-height: 100vh;
  margin-top: -6rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items:center;
}*/

.rawb0tsApp__mint {
  overflow: hidden;
  background: var(--mint-bg);
  min-height: 100vh;
  margin-top: -6rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.rawb0tsApp__mint-bg {
  min-height: 100vh;
  width: 100%;
  padding: 11rem 2rem 2rem 2rem;
  background-blend-mode: overlay;
  background-image: url('../../assets/images/mint_BG01.png');
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.rawb0tsApp__mint h3 {
  color: var(--mint-toptitle);
}

.rawb0tsApp__mint-box {
  display: flex;
  flex-direction: column;
  background: var(--mint-info-bg);
  padding: 3rem;
  border-bottom: solid #000 15px;
  max-width: 980px;
} 

.rawb0tsApp__mint-numbers {
   display: flex;
   flex-wrap: wrap;
   max-width: 260px;
}

.rawb0tsApp_mint-numbers_available,
.rawb0tsApp_mint-numbers_unavailable {
  display: flex;
  flex-direction: row;
  padding-top: 1.5rem;
  align-items: center;
}

.rawb0tsApp_mint-numbers_available img,
.rawb0tsApp_mint-numbers_unavailable img {
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
  }

.rawb0tsApp_mint-numbers_available p,
.rawb0tsApp_mint-numbers_unavailable p {
  font-weight: 500;
  font-family: 'Rajdhani', sans-serif !important;
  text-transform: uppercase;
  font-size: 18px !important;
  text-rendering: optimizeLegibility;
}

.rawb0tsApp_mint-numbers_available p {
  color: var(--mint-available) !important;
}

.rawb0tsApp_mint-numbers_unavailable p {
  color: var(--mint-unavailable) !important;
}

.rawb0tsApp__mint-numbers button {
  margin: 2rem 0rem;
  color: #fff;
  padding: 1rem 2rem;
  font-family: 'Rajdhani', sans-serif;
  font-size: 38px;
  border-width: 0;
  border-radius: 5px;
  min-width: 520px;
  z-index: 1;
  cursor: pointer;
  flex-wrap: nowrap;

 } 

.rawb0tsApp__mint-numbers button:hover {
  color: #fff;
}

 .rawb0tsApp__mint-numbers h2 {
  color: var(--hp);
  font-size: 80px;
  line-height: 65px;
}

.rawb0tsApp__mint-numbers p {
color: #fff;
font-family: 'Epilogue', sans-serif;
line-height: 1.4rem;
font-size: 15px;
}

.rawb0tsApp__mint_contents {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.rawb0tsApp__mint-box_info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 3rem;
  z-index: 1;
  background: #312a3e;
}

.rawb0tsApp__mint-box_info p {
  color: #fff;
  font-family: 'Epilogue', sans-serif;
  line-height: 1.4rem;
  font-size: 15px;
}

.rawb0tsApp__mint-box_lootbox img{
  width: 553px;
  display: flex;
  justify-content: flex-end;
  min-width: 100px;
  image-rendering: pixelated;
  margin-top: -100px;
}

.rawb0tsApp_mint-box_gif img {
  max-width: 320px;
  display: flex;
  image-rendering: pixelated;
}

.rawb0tsApp_mint-box_gif-and-info {
  display: flex;
  flex-direction: row;
  max-width: 980px;
}

.rawb0tsApp_mint-box_purpleTriangle {
  display: flex;
  justify-content: flex-end;
  padding-right: 8rem;
}

.rawb0tsApp_mint-box_purpleTriangle img {
  width: 88px;
  height: 44px;
  margin-top: 3rem;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 880px) {
  .rawb0tsApp__mint {
    padding: 9rem 1rem 1rem 1rem;
    min-height: 100vh;
    margin-top: -6rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .rawb0tsApp__mint-box {
    margin: 1.5rem;
    padding: 3rem;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .rawb0tsApp__mint_contents {
    flex-direction: column-reverse;
  }

  .rawb0tsApp__mint-box_lootbox img{
    display: flex;
    flex-wrap: nowrap;
    max-width: 100%;
  }

  .rawb0tsApp__mint-numbers {
   display: flex;
   flex-wrap: wrap;
   max-width: 400px;
  }

   .rawb0tsApp__mint-numbers h2 {
    color: var(--hp);
    font-size: 60px;
    line-height: 45px;
  }

  .rawb0tsApp__mint-numbers h3 {
    display: flex;
    min-width: 100%;
  }

  .rawb0tsApp_mint-box_gif-and-info {
    display: flex;
    margin: 0rem 1.5rem 1.5rem 1.5rem;
    flex-direction: column-reverse;
  }

  .rawb0tsApp_mint-box_gif img {
    display: flex;
    max-width: 100%;
  }

  .rawb0tsApp__mint-box_info {
    display: flex;
    max-width: 100%;
  }

  .rawb0tsApp_mint-box_purpleTriangle img {
      margin-top: 0rem;
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
  }

  .rawb0tsApp__mint-numbers button {
    margin: 2rem 0rem;
    color: #fff;
    padding: 1rem 2rem;
    font-family: 'Rajdhani', sans-serif;
    font-size: 28px;
    border-width: 0;
    border-radius: 5px;
    min-width: 320px;
    z-index: 1;
    cursor: pointer;
    flex-wrap: wrap;
  
   } 

}